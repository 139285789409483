import Transition from '../components/transition'

import React, { Component } from 'react'
import '../styles/nara.scss'
import * as PropTypes from 'prop-types'
import { isBrowser } from '../utilities'
/* eslint-disable */
// import { hotjar } from 'react-hotjar'
/* eslint-enable */
import { PageHelmet } from './helmet'
import Navigation from './navigation'
import Captcha from '../captcha'
//import smootScroll from '../lib/smoothScroll'
import CookieBar from '../components/CookieBar'


export const LangContext = React.createContext('lang')

class Layout extends Component {
  constructor(props) {
    super(props)
    /*    if (typeof document !== 'undefined') {
          hotjar.initialize(1550535, 6)
        }*/
    //const readyState = typeof document !== 'undefined' ? document.readyState : 'unsent'

    this.state = {
      isMobileMenuOpen: false,
      lastPath: null,
    }
  }


  componentDidMount() {
   // smootScroll();
  }
  render() {
    const { children, location, pageContext, showBackButton } = this.props
    // Disable rightclick on whole web
    if (isBrowser() && window.location.hostname !== "localhost" && window.location.hostname.search('test') === false) {
      document.addEventListener('contextmenu', event => event.preventDefault(), {passive: true});
    }

    if (location.pathname === '/offline-plugin-app-shell-fallback') return null
    
    const currentPath = location.pathname

    const lang = currentPath.indexOf('/en') === 0 ? 'en' : 'sk'
    
    const showFormLayout = pageContext.layout === 'brief'
    if (typeof document !== 'undefined') {
      if (showFormLayout) {
        document.body.classList.add('brief')
      } else {
        document.body.classList.remove('brief')
      }
    }

    const path = location.pathname.split('#')[0].replace(/\/$/, '')
    const isCaseStudy = path.indexOf('/pripadove-studie') !== -1 //TODO: En
    return (
    
          
      <div id='layout' className={`layout ${isCaseStudy ? 'case-study' : ''}`} style={{ opacity: 0 }}>
        
        {/*<FacebookProvider appId="452454992040565" chatSupport={true} wait={true}>*/}
        <LangContext.Provider value={lang}>

          <PageHelmet />

          {!showFormLayout && <Navigation location={location} lang={lang} showBackButton={showBackButton} isCaseStudy={isCaseStudy} />}

          <div id={'scroll-el'}>
            <div id={'main'}>
              <div className="with-bg"></div>
              {showFormLayout ?
                <div data-location={location.pathname}>{children}</div> :
                <Transition location={location}>{children}</Transition>
              }
            </div>
            {!showFormLayout && <CookieBar />}
          </div>
          {/*<script src="/js/jquery-ui.min.js"/>*/}
          {/*<script src="/js/jquery.easing.min.js"/>*/}
          {/*<script src="/js/jquery.nice-select.min.js"/>*/}
          {/*<script src="/js/owl.carousel.min.js"/>*/}
          {/*<script src="/js/responsive-nav.js"/>*/}
          {/*<script src="/js/popper.min.js"/>*/}
          {/*<script src="/js/nara.js"/>*/}
          {/*<script src="/bootstrap/js/bootstrap.min.js"/>*/}

          {/*<CustomChat
              pageId="267757090589101"
              minimized={false}
              loggedInGreeting={'Dobrý deň, poradíme Vám? Napíšte nám priamo sem alebo zavolajte na 0918 040 888'}
              loggedOutGreeting={'Dobrý deň, poradíme Vám? Napíšte nám priamo sem alebo zavolajte na 0918 040 888'}
              themeColor={'#014EFF'}
            />*/}
 
          <Captcha />
        </LangContext.Provider>
        {/*</FacebookProvider>*/}
        </div>
   
    )
  }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    showBackButton: PropTypes.bool,
    hideFooter: PropTypes.bool,
    lang: PropTypes.string,
}

Layout.defaultProps = {
    showBackButton: false,
    hideFooter: false
}

export default Layout
