import en from './languages/en/translations'
import sk from './languages/sk/translations'
import { getSlug } from './getSlug'
import { navigate } from 'gatsby'
import * as React from 'react'

export const slugFormat = (title, prefix) => {
  if (prefix === 'en') {
    prefix = null
  }
  if (title && title.length > 0) {
    const slug = title
      .normalize('NFD')
      .trim()
      .replace(/\s+/g, '-')
      .replace(/\./g, '')
      .replace(/\?/g, '')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
    if (prefix != null && prefix.length > 0) {
      return `${prefix}/${slug}`
    }
    return slug
  }
  return '#'
}



export const gs = getSlug

export const getCategoryDisplayName = (categoryId, lang = 'sk', transKey = 'Categories') => {
  const t = getTranslateFunction(lang)

  if (categoryId == null) {
    return 'Null'
  }
  categoryId = categoryId.toLowerCase()
  /*
    const categories = {
      'web-corporate': 'WebstrÃ¡nky - Corporate',
      'web-eshop': 'WebstrÃ¡nky - Eshop',
      'web-portal': 'WebstrÃ¡nky - Portal',
      'web-microsite': 'WebstrÃ¡nky - Microsite',
      'web-crm': 'WebstrÃ¡nky - CRM',
      'app-android': 'AplikÃ¡cie - Android',
      'app-ios': 'AplikÃ¡cie - iOS',
      'app-multi-platform': 'AplikÃ¡cie - Multi-platform',
      'graphic-logo-branding': 'Grafika - Logo/Branding',
      'graphic-digital/print': 'Grafika - Digital/Print',
    }
  */

  const categories = {
    'firemne': t(`Misc.${transKey}.web-corporate`),
    'web-corporate': t(`Misc.${transKey}.web-corporate`),
    'web-eshop': t(`Misc.${transKey}.web-eshop`),
    'web-portal': t(`Misc.${transKey}.web-portal`),
    'web-microsite': t(`Misc.${transKey}.web-microsite`),
    'web-crm': t(`Misc.${transKey}.web-crm`),
    'app-android': t(`Misc.${transKey}.app-android`),
    'app-ios': t(`Misc.${transKey}.app-ios`),
    'app-multi-platform': t(`Misc.${transKey}.app-multi-platform`),
    'graphic-logo-branding': t(`Misc.${transKey}.graphic-logo-branding`),
    'graphic-digital/print': t(`Misc.${transKey}.graphic-digital/print`),

    'corporate': t(`Misc.${transKey}.web-corporate`),
    'eshop': t(`Misc.${transKey}.web-eshop`),
    'portal': t(`Misc.${transKey}.web-portal`),
    'microsite': t(`Misc.${transKey}.web-microsite`),
    'crm': t(`Misc.${transKey}.web-crm`),
    'android': t(`Misc.${transKey}.app-android`),
    'ios': t(`Misc.${transKey}.app-ios`),
    'app': t(`Misc.${transKey}.app-multi-platform`),
    'multi_platform': t(`Misc.${transKey}.app-multi-platform`),
    'logo_branding': t(`Misc.${transKey}.graphic-logo-branding`),
    'digital_print': t(`Misc.${transKey}.graphic-digital/print`),
  }

  return categories[categoryId] ? categories[categoryId] : ''
}


export const getTranslatedSlug = (slug) => {
  const slugPairs = {
    '/en': '/',
    '/en404': '/404',
    '/en/services': '/sluzby',
    '/en/services/service': '/sluzby/servis',
    '/en/services/design': '/sluzby/dizajn',
    '/en/services/programming': '/sluzby/programovanie',
    '/en/services/create-website': '/tvorba-webov',
    '/en/wodpress-a-woocommerce': '/wodpress-a-woocommerce',
    '/en/tvorba-web-aplikacii': '/tvorba-web-aplikacii',
    '/en/tvorba-mobilnych-aplikacii': '/tvorba-mobilnych-aplikacii',
    '/en/about-us': '/o-nas',
    '/en/about-us/ux-principes': '/o-nas/ux-principy',
    '/en/about-us/clients': '/o-nas/klienti',
    '/en/about-us/how-we-work': '/o-nas/ako-pracujeme',
    '/en/our-works': '/nase-prace',
    '/en/our-works/featured': '/nase-prace/vybrane',
    '/en/our-works/websites': '/nase-prace/webstranky',
    '/en/our-works/applications': '/nase-prace/aplikacie',
    '/en/our-works/graphics': '/nase-prace/grafika',
    '/en/blog': '/blog',
    '/en/contact': '/kontakt',
    '/en/privacy-policy': '/zasady-spracuvania-osobnych-udajov',
    '/en/brief': '/dotaznik',
    '/en/eshop-na-mieru': '/eshop-na-mieru',
    '/en/dessign': '/webdizajn',
    '/en/ux-ui-dizajn': '/ux-ui-dizajn',
    '/en/tvorba-loga': '/tvorba-loga',
    '/en/branding': '/branding',
    '/en/graficky-dizajn': '/graficky-dizajn',
  }

  if (slug in slugPairs) {
    return slugPairs[slug]
  } else if (Object.keys(slugPairs).indexOf(slug) > -1) {
    return Object.keys(slugPairs).find((key) => slugPairs[key] === slug)

  } else {
    return '#'
  }
}

export const matchPath = (path, find, startWith = false) => {
  const nPath = path.replace(/\/$/, '')
  const nFind = find.replace(/\/$/, '')

  if (startWith) {
    return nPath.startsWith(nFind) || nPath.startsWith(getTranslatedSlug(nFind))
  }

  return nPath === (nFind) || nPath === nFind || nPath === getTranslatedSlug(nFind)
}

export const getTranslateFunction = (lang_name) => {
  const lang = lang_name === 'en' ? en : sk
  return (query) => {
    let langPart = { ...lang }
    for (let q of query.split('.')) {
      if (q in langPart) {
        langPart = langPart[q]
      } else {
        console.error(`Error while getting translation for ${query} in ${lang_name} language!`)
        return 'Null'
      }
    }
    return langPart
  }
}

export const getCategorySlug = (category, lang) => {
  const en = {
    'web-corporate': 'websites/corporate',
    'web-eshop': 'websites/eshop',
    'web-portal': 'websites/portal',
    'web-microsite': 'websites/microsite',
    'web-crm': 'websites/crm',
    'app-android': 'applications',
    'app-ios': 'applications',
    'app-multi-platform': 'applications',
    'graphic-logo-branding': 'graphics/logo-branding',
    'graphic-digital/print': 'graphics/digital-print',

    'corporate': 'websites/corporate',
    'eshop': 'websites/eshop',
    'portal': 'websites/portal',
    'microsite': 'websites/microsite',
    'crm': 'websites/crm',
    'app': 'applications',
    'logo_branding': 'graphics/logo-branding',
    'digital_print': 'graphics/digital-print',
  }

  const sk = {
    'corporate': 'webstranky/firemne',
    'eshop': 'webstranky/eshopy',
    'portal': 'webstranky/portaly',
    'microsite': 'webstranky/microsite',
    'crm': 'webstranky/crm',
    'app': 'aplikacie',
    'logo_branding': 'grafika/logo-branding',
    'digital_print': 'grafika/digital-print',

    'firemne': 'webstranky/firemne',
    'web-eshop': 'webstranky/eshopy',
    'web-portal': 'webstranky/portaly',
    'web-microsite': 'webstranky/microsite',
    'web-crm': 'webstranky/crm',
    'app-android': 'aplikacie',
    'app-ios': 'aplikacie',
    'app-multi-platform': 'aplikacie',
    'graphic-logo-branding': 'grafika/logo-branding',
    'graphic-digital/print': 'grafika/digital-print',
  }

  return lang === 'en' ? en[category] : sk[category]
}
export const getCategorySubCategorySlug = (category, lang) => {
  const en = {
    'web-corporate': 'websites#corporate',
    'web-eshop': 'websites#eshop',
    'web-portal': 'websites#portal',
    'web-microsite': 'websites#microsite',
    'web-crm': 'websites#crm',
    'app-android': 'applications',
    'app-ios': 'applications',
    'app-multi-platform': 'applications',
    'graphic-logo-branding': 'graphics#logo-branding',
    'graphic-digital/print': 'graphics#digital-print',

    'corporate': 'websites#corporate',
    'eshop': 'websites#eshop',
    'portal': 'websites#portal',
    'microsite': 'websites#microsite',
    'crm': 'websites/crm',
    'app': 'applications',
    'logo_branding': 'graphics#logo-branding',
    'digital_print': 'graphics#digital-print',
  }

  const sk = {
    'web-corporate': 'webstranky#firemne',
    'eshop': 'webstranky#eshop',
    'portal': 'webstranky#portal',
    'microsite': 'webstranky#microsite',
    'crm': 'webstranky#crm',
    'app': 'aplikacie',
    'logo_branding': 'grafika#logo-branding',
    'digital_print': 'grafika#digital-print',

    'firemne': 'webstranky#firemne',
    'web-eshop': 'webstranky#eshop',
    'web-portal': 'webstranky#portal',
    'web-microsite': 'webstranky#microsite',
    'web-crm': 'webstranky#crm',
    'app-android': 'aplikacie',
    'app-ios': 'aplikacie',
    'app-multi-platform': 'aplikacie',
    'graphic-logo-branding': 'grafika#logo-branding',
    'graphic-digital/print': 'grafika#digital-print',
  }

  return lang === 'en' ? en[category] : sk[category]
}



export const isBrowser = () => {
  return typeof window !== 'undefined' && typeof document !== 'undefined'
}

export const getWindowWidth = () => {
  if (typeof window === 'undefined' && typeof document === 'undefined') return 0
  return window.innerWidth
}


export const translatedNavigate = (url, lang) => {
  navigate(lang === 'en' ? url : getTranslatedSlug(url))
}

export const getRandomStr = () => {
  return Math.random().toString(36).substr(2, 9)
}

export const mapRange = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2

export const degrees_to_radians = (degrees) => {
  return degrees * (Math.PI / 180)
}

export const isSafari = () => {
  if (typeof window === 'undefined') return false
  return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
}

export const scrollIntoView = (el, center = true, inline = 'start', offsetTop = 0, speed = 1) => {
  
  if (el == null) return;
  if (!isSafari()) {
    if (offsetTop !== 0 || speed !== 1) {
      const y = el.getBoundingClientRect().top + window.pageYOffset + offsetTop;
      window.scrollTo(1, y);

    } else {
      el.scrollIntoView({
        behavior: 'smooth',
        block: center ? 'center' : 'start',
        inline: inline === null ? undefined : inline
      })
    }
  } else {
    if (typeof window !== 'undefined') {
      const elementRect = el.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.pageYOffset
      const middle = absoluteElementTop - (window.innerHeight / 2) + (elementRect.height / 2)
      window.scrollTo(0, center ? middle : absoluteElementTop)
    }
  }
}

export const scrollToTarget = function (target, duration = 800) {
    const top = target.getBoundingClientRect().top;
    const startPos = window.pageYOffset;
    const diff = top;

    let startTime = null;
    let requestId;

    const loop = function (currentTime) {
        if (!startTime) {
            startTime = currentTime;
        }

        // Elapsed time in miliseconds
        const time = currentTime - startTime;

        const percent = Math.min(time / duration, 1);
        window.scrollTo(0, startPos + diff * percent);

        if (time < duration) {
            // Continue moving
            requestId = window.requestAnimationFrame(loop);
        } else {
            window.cancelAnimationFrame(requestId);
        }
    };
    requestId = window.requestAnimationFrame(loop);
};

export const uppercaseFirstLetter = function (string) {
  return string.substring(0, 1).toUpperCase() + string.substring(1);
}
export class AnchorClickToNavigateWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
    this.updateLinksHandlers = this.updateLinksHandlers.bind(this)
  }
  updateLinksHandlers() {
    setTimeout(() => {
      if (this.ref && this.ref.current) {
        this.ref.current.querySelectorAll('a').forEach((el) => {
          el.onclick = (event) => {
            event.preventDefault()
            navigate(event.target.getAttribute('href'))
          }
        },
        )
      }
    }, 0)
  }

  componentDidMount() {
    this.updateLinksHandlers()
  }

  render() {
    const { children } = this.props
    this.updateLinksHandlers()
    return <div ref={this.ref}>{children}</div>
  }
}

export const shiftHTags = (html) => {
  if(!html) return html;
  return html.replace(/<h6>/g, '<p class="blog-small-text">')
  
  return html.replace(/<h2>/g, '<h3>')
    .replace(/<\/h2>/g, '</h3>')
    .replace(/<h1>/g, '<h2>')
    .replace(/<\/h1>/g, '</h2>')

}

export const toLineBreak = (html) => {
  if(!html) return html;
  return html.replaceAll('$$', '<br>')
}

export const removeNumberPrefix = (str) => {
  return replaceUnderscore(str.replace(/^[0-9][0-9]/gm, ''));
}
export const replaceUnderscore = (str) => {
  return str.replaceAll('_', ' ')
}

export const convertNonBreakingSpace = (str) => {
  return str.replace('&nbsp', " ");
}

export const scrollToSlug = () => {
  setTimeout(() => {
    let id = window.location.hash.replace("#", "")
    let element = document.getElementById(id);
    if (element) {
      element.scrollIntoViewIfNeeded(false);
    }
  }, 100);
}

export const removeHtml = (html) => {
  if(!html) return html;
  if (typeof document !== 'undefined') {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }
  return html
}

export const toTwoDigit = (number) => {
  if (number < 9)
    return "0" + number;
  else
    return number;

}