import React, { useState } from 'react'
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { TranslatedLink } from './TranslatedLink'
import LinkBtn from './linkBtn/LinkBtn'
import Modal from './pages/modal/Modal'
import SwitchInput from './inputs/SwitchInput';
import {  isBrowser, getTranslateFunction } from '../utilities'
import eventBus from "../eventBus";
import HoverBtn from './HoverBtn';

const HIDE_COOKIE_BAR_LS_KEY = 'narative-hide-cookie-bar'
const GOOGLE_TAG_MANAGER_KEY = 'narative-gdpr-google-tagmanager'
const PIXEL_KEY = 'narative-gdpr-facebook-pixel'

//google tagmanager activate
  if (isBrowser()) {
    document.cookie = GOOGLE_TAG_MANAGER_KEY + '=true';
  }


const modalRef = React.createRef(null);

function getValue(key, defaultValue) {
  return isBrowser() && window.localStorage.getItem(key) && isJsonString(window.localStorage.getItem(key))
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue, key) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}


const isJsonString = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

const CookieBar = (lang) => {
  const slug = lang === 'en' ? '/en/privacy-policy' : '/zasady-spracuvania-osobnych-udajov/';
  const t = getTranslateFunction(lang)
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }
  const [cookieManagerHidden, setCookieManagerHidden] = useStickyState(false, HIDE_COOKIE_BAR_LS_KEY);
  const [loaded, setLoaded] = useState(false);
 

  const enable = () => {
    document.cookie = HIDE_COOKIE_BAR_LS_KEY + '=' + tagManagerValue;
    document.cookie = GOOGLE_TAG_MANAGER_KEY + '=' + tagManagerValue;
    document.cookie = PIXEL_KEY + '=' + tagManagerValue;
    setCookieManagerHidden(true);
  };
  
  const onClickChild = () => {
    modalRef.current.showModal();
  }
  
  const [ tagManagerValue, setTagManagerValue ] = React.useState(true);

  const onSubmitCookiesForm = () => {
    enable()
  }

  React.useEffect(() => {
    setLoaded(true)
    eventBus.on("showCookieBar", (data) => {
      //setCookieManagerHidden(false)
      modalRef.current.showModal();
    });
    return () => {
      eventBus.remove("showCookieBar");
    };
  }, [setLoaded, setCookieManagerHidden])

  

  return (
    <>
      {(!cookieManagerHidden && loaded) && (
        <div className={`cookie-bar`} >
          <div className={'container'}>
            <div className="row">
              <div className={'col-12 col-lg-6 col-xl-7'}><span dangerouslySetInnerHTML={{__html:t('cookieBar.Text1')}}></span><TranslatedLink lang={lang} to={slug}>{t('cookieBar.Text2')}</TranslatedLink>.</div>
              <div className="col-auto mr-auto ml-auto mr-lg-0 pt-2 pt-md-0 d-flex align-items-center">
                <HoverBtn handleClick={enable}>
                  <div>{t('cookieBar.BtnSubmit')}</div>
                </HoverBtn>
                <LinkBtn className={'silver ml-5 text-1'}>
                  <span onClick={onClickChild}>{t('cookieBar.BtnEdit')}</span>
                </LinkBtn>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal className="cookie-bar-modal" closeBtn={t('cookieBar.Cancel')} submitBtn={t('cookieBar.Save')} size="lg" onSubmitHandle={onSubmitCookiesForm} ref={modalRef} showHeader={false}>
        <div className="row">
          <div className="col-12 mt-md-3">
            <h4 className="mb-1 mb-md-3"><span dangerouslySetInnerHTML={{ __html: t('cookieBar.NesesseryTitle') }}></span></h4>
            <SwitchInput defaultValue={true} disabled={true}>
              <span dangerouslySetInnerHTML={{ __html: t('cookieBar.NesesseryText') }}></span>
            </SwitchInput>
          </div>  
          <div className="col-12 mt-3 mt-md-5">
            <h4 className="mb-1 mb-md-3"><span dangerouslySetInnerHTML={{ __html: t('cookieBar.OptionalTitle') }}></span></h4>
            <SwitchInput defaultValue={tagManagerValue} onChange={setTagManagerValue}><span dangerouslySetInnerHTML={{ __html: t('cookieBar.OptionalText') }}></span></SwitchInput>
          </div>  
        </div>
      </Modal>
    </>
  );
};

export default CookieBar